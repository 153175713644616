.dice.big {
    width: 7.9em;
    height: 7.9em;
    cursor: initial;
    border-radius: 0.8em;
}

.dice {
    width: 3.33em;
    height: 3.33em;
    cursor: pointer;
    margin: 0.1em;
    transition-duration: 200ms; 
    background-color: white;
    border-radius: 0.33em;
}

.dice:hover {
    background-color: #fccd83;
}

.dice.big:hover {
    background-color: white;
}

.dice.preSelect {
    background-color: #ccc;
}

.dice.active {
    background-color: #F9A826;
}
