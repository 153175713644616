@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  25%, 75% {
    transform: translate3d(-4px, 0, 0);
  }
  50% {
    transform: translate3d(4px, 0, 0);
  }
}

.shaker {
    height: 100%;
}

.shaker.shake *{
    animation: shake 400ms cubic-bezier(.36,.07,.19,.97) both infinite;
    transform: translate3d(0, 0, 0);
}

.shaker.shake .view-button-line {
    display: none;
}
