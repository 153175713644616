 .itemList-Container {
     overflow: visible;
 }

 .itemList-Container .arrow {
     position: absolute;
     top: -2em;
     left: calc(50% - 1.5em);
     width: 3em;
     height: 3em;
 }

.itemList {
    margin-top: 0.2em;
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 20%;
    border-bottom: 1px solid #252525;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
 }

.itemList.big {
    height: 40%;
    border-bottom: 0;
 }

 .itemList.big::after {
     height: 40%;
 }
 
.itemList .item {
    padding: 0 5pt;
}

.itemList .item.active {
    font-size: 2em;
    font-weight: 600;
}

.itemList .item.next {
    font-size: 1.6em;
    font-weight: 400;
}

.itemList .item.more {
    font-size: 1.2em; 
    font-weight: 200;
}

.itemList .item.moore {
    font-size: 1em;
    font-weight: 100;
}

.itemList .item.mooore {
    font-size: 0.8em;
    font-weight: 50;
}
