.input-field {
    text-align: left;
    margin: 1em;
    padding-left: 2em;
    padding-right: 2em;
}

.input-field label {
    font-size: 1.5em;
}

.input-field input {
    border: 1px solid #3F3D56;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: "Permanent Marker";
    text-align: center;
    font-size: 1.5em;
    width: 100%;
    padding: 0.5em;
    outline: none;
}

.input-field input.focus {
    border: 1px solid black;
}
