.welcome-view .game-aliases {
    position: absolute;
    left: -8em;
    top: 0.5em;
    font-size: 2em;
    line-height: 1.5em;
    text-align: center;
    transform: rotate(-16.5deg);
}

.welcome-view .prost {
    position: absolute;
    right: -4em;
    bottom: 0.5em;
    font-size: 3.5em;
    text-align: center;
    transform: rotate(12.0deg);
}

.welcome-view .view-play .info-text {
    margin: 2em;
}

.welcome-view .view-play .info-text div {
    font-size: 1.3em;
}

.welcome-view .view-play .info-text a {
    color: black;
    font-size: 1.3em;
}

.welcome-view .view-message-content {
    justify-content: flex-start;
}
