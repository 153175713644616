.view {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    max-width: 768px;
    height: 100%;
}    

.view-message {
    flex: 0 0 20vh;
    color: black;
    padding-left: 2vh;
    padding-right: 2vh;
    margin: 2vh;
    z-index: 1;
}

.view-message-content {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.view-info {
    flex: 1 1 40vh;
    align-self: center;
    position: relative;
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.view-info-content {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
    
.view-play {
    flex: 0 0 35vh;
    background-color: white;
    color: black;
    border-radius: 0.9em;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 1.5em;
    text-align: center;
    position: relative;
}

.view-play .view-button-line {
    margin-left: 1.5em;
    margin-right: 1.5em;
    position: absolute;
    bottom: 1em;
    width: calc(100% - 3em) ;
    height: 3em;
}

.view-button-line > button.small:nth-child(1) {
    float: left;
}

.view-button-line > button.small:nth-child(2) {
    float: right;
}

.view-button-line > button.small:nth-child(3) {
    position: relative;
    float: right;
    bottom: 7em;
}

.shake .view-botton-line {
    display: none;
}
