html, body {
    box-sizing: border-box;
    height: 100%;
    font-family: "Permanent Marker";
    font-size: 18px;
    margin: 0;
}

#root {
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
}

#root:before {
    content: " ";
    background-image: url("./img/foam.svg");
    background-position: center;
    width: 100vw;
    height: 242px;
    position: absolute;
}

@media only screen and (max-width: 700px) {
    html, body {
        font-size: 12px;
    }
}

@media only screen and (max-width: 375px), (max-height: 720px) {
    html, body {
        font-size: 8px;
    }
}

img {
    max-height: 100%;
    max-width: 786px;
}
