.DiceSelect {
    margin-top: 1.5em;
    margin-bottom: 1.5em; 
    align-content: center;
    flex-direction: row;
    display: flex;
}

.DiceSelect .DiceOne{
    flex: 1;
    display: inline-grid;
    justify-content: center;
    grid-row-gap: 0.21em;
    width: 40%;
}

.DiceSelect .withAir {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.DiceSelect .DiceTwo {
    flex: 1;
    display: inline-grid;
    justify-content: center;
    grid-row-gap: 0.21em;
    width: 40%;
}
