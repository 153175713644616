 .dicePresentation::before {
     position: absolute;
     left: -5%;
     bottom: -1.4em;
     z-index: -1;
     content: '';
     width: 110%;
     height: 40%;
     border-radius: 50%;
     background-color: rgba(255, 255, 255, 0.9);
     box-shadow: 0 4px 4px rgba(0,0,0,0.25);
 }

 .dicePresentation {
     display: flex;
     flex-direction: row;
     align-content: center;
     position: relative;
     z-index: 1;
     margin-bottom: 4em;
     min-width: 55%;
 }

.dicePresentation > .diceItem::before {
     position: absolute;
     bottom: -0.55em;
     width: 10em;
     height: 1.8em;
     content: " ";
     background-color: rgba(0, 0, 0, 0.32);
     filter: blur(4px);
     border-radius: 100%;
 }

.dicePresentation > .diceItem:nth-child(1)::before {
    left: 6%;
}

.dicePresentation > .diceItem:nth-child(2)::before {
    right: 6%;
}

.dicePresentation > .diceItem {
     flex: 1;
     display: flex;
     position: relative;
     padding-left: 1.44em;
     padding-right: 1.44em;
}

.dicePresentation > .diceItem:nth-child(1) {
     justify-content: right;
}

.dicePresentation > .diceItem:nth-child(2) {
     justify-content: left;
}

.dicePresentation > .diceItem > .dice {
     z-index: 1;
}
