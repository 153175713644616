.background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
    overflow: hidden;
    pointer-events: none;
    background: linear-gradient(180deg, #FFFFFF 15.62%, #FFD600 21.88%, #FFE350 67.19%);
}

.background .background-bubble {
    position: absolute;
    overflow: hidden;
    top: -1000px;
}

.background .background-bubble.speed1 {
    animation: move_up 10s linear;
}

.background .background-bubble.speed2 {
    animation: move_up 8s linear;
}

.background .background-bubble.speed3 {
    animation: move_up 9s linear;
}

.background. .background-bubble.speed4 {
    animation: move_up 8.58.58.5s linear;
}

.background .background-bubble.speed5 {
    animation: move_up 9.2s linear;
}

.background .background-bubble.speed6 {
    animation: move_up 6s linear;
}

@keyframes move_up {
    from { top: calc(100vh) }
    to { top: -400px;}
}
