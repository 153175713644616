.button {
    border-radius: 16pt;
    font-family: Open Sans, sans-serif;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 0em;
    text-align: center;
    background-color: #F9A826;
    color: white;
    border: 0;
    padding: 1em;
    text-transform: uppercase;
    transition-property: background-color;
    transition-duration: 600ms;
    cursor: pointer;
    /* margin: 10pt 0; */
}

.button:focus {
    outline: 0;
}

.button:active {
    transform: translateY(1px);
}

.button {
    color: white;
}

.button:hover {
    background-color: #c77d05;
    /* background-color: #fccd83; */
}

.button.light {
    background-color: #ffffff;
    color: #000;
}

.button.light:hover {
    background-color: #eeeeee;
}

.button.red {
    background-color: #E63946;
}

.button.red:hover {
    background-color: #cb1927;
}

.button.big {
    width: 100%;
}

.button.small {
    width: 49%;
}

.button:disabled {
    color: grey !important;
    border-color: grey !important;
    cursor: not-allowed;
}
