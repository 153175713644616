.App {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 95vw;
    max-width: 786px;
}

.App-content {
    height: 100%;
}
